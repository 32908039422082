<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <!-- <img :src="'layout/images/logo-' + logoColor + '.svg'" alt="Sakai logo" class="mb-5" style="width:81px; height:60px;"> -->
                <img src="layout/images/logoinvsifazul.png" alt="Image" style="height:150px;" class="" />
                
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        
                        <div class="text-900 text-3xl font-medium mb-3">Bienvenido!</div>
                        <span class="text-600 font-medium">Inicio de Sesión</span>
                    </div>
                    <form action class="form" @submit.prevent="login">
                        <div class="w-full md:w-10 mx-auto">
                            <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
                            <InputText id="email1" v-model="email" type="text" class="w-full mb-3" placeholder="Email" style="padding:1rem;" />
                    
                            <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>                            
                            <InputText id="password1" v-model="password" type="password" class="w-full mb-3" placeholder="Password" style="padding:1rem;" />
                    
                            <p v-if="error" class="error">
                                Has introducido mal el email o la contraseña.
                            </p>                   

                            <Button type="submit" class="w-full p-3 text-xl" label="Ingresar"></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script >
import { login } from '@/auth/user';

export default {    
    data() {
        return {
            email: '',
            password: '',
            error: false,            
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    methods: {

        login(){
            var params = {
                user: this.email,
                password: this.password
            }

            login(params);
                                   
            this.email = "";
            this.password = "";            
        }
    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>